import React from 'react'
import useMessage from '@hooks/useMessage'
import useCartDetails from '@page-components/Order/Cart/Content/hooks/useCartDetails.main'
import SelectProduct from '@page-components/Order/Products/ProductsContent/SelectProduct'
import {useRefetchQueries} from 'apollo-hooks'
export default function EditProduct({
  cartItemId,
  onClose,
  setLoading,
  isOpen
}: {
  cartItemId: string
  onClose: () => void
  setLoading: (loading: boolean) => void
  isOpen: boolean
}) {
  const refetch = useRefetchQueries()
  const cart = useCartDetails()
  const showMessage = useMessage()
  const item = cart?.items.find(i => i._id === cartItemId)

  if (!item) return
  return (
    <SelectProduct
      product={item.product}
      open={isOpen}
      cartItem={item}
      onAdd={async () => {
        setLoading(true)
        await refetch(['getCartDetails', 'getPreferencesForResume']).catch(() =>
          showMessage(new Error('Ocurrió un error al editar el producto. Intente nuevamente.'))
        )
        setLoading(false)
      }}
      close={onClose}
    />
  )
}
