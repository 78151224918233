import Button from '@components/V3/Button'
import {EVENTS} from '@helpers/analytics/events'
import {PurchaseTaxonomies} from '@helpers/analytics/events/purchase'
import {sendEvent} from '@helpers/analytics/sendEvents'
import isLoginWithExternalProvider, {
  loginExternalProviderPath,
} from '@helpers/auth/loginWithExternalProvider'
import useTrackEcommerceEvent, {ECOMMERCE_EVENTS} from '@helpers/track/useTrackEcommerceEvent'
import useEventsBaseProperties from '@hooks/useEventsBaseProperties'
import useIsAnalyticsV2Avail from '@page-components/AnalyticsV2/hooks/useIsAnalyticsV2Available'
import useLazyAnalyticsV2 from '@page-components/AnalyticsV2/hooks/useLazyAnalyticsV2'
import {INTERNAL_EVENTS} from '@page-components/AnalyticsV2/types/analyticsServicesTypes'
import WarningBox from '@page-components/Order/warningBox'
import {useTranslation} from 'next-i18next'
import {useRouter} from 'next/router'

export default function ContinueButton(props) {
  const {t} = useTranslation()
  const router = useRouter()
  const {redirecting, preferences, website, setRedirecting} = props
  const {cart, userId} = preferences
  const i18nProduct = 'order.products.productsContent.selectProduct'
  const i18nGeneric = 'generic:fields'
  const checkoutPath = '/checkout'
  const baseEventProperties = useEventsBaseProperties()
  const trackEcommerceEvent = useTrackEcommerceEvent()
  const isAnalyticsV2Avail = useIsAnalyticsV2Avail()
  const analyticsV2 = useLazyAnalyticsV2()

  const redirectToLoginWithExternalProvider =
    !userId && isLoginWithExternalProvider(website?.loginWithExternalProvidersAvailable)

  const goToReview = () => {
    if (!isAnalyticsV2Avail) {
      trackEcommerceEvent(ECOMMERCE_EVENTS.INITCHECKOUT, {cart})
      sendEvent<PurchaseTaxonomies['continueToCheckout']>(EVENTS.purchase.continueToCheckout, {
        ...baseEventProperties,
      })
    } else {
      analyticsV2.trackEventAsDefaultOnInternal(INTERNAL_EVENTS.continueToCheckout)
    }
    setRedirecting(true)
    router.push(redirectToLoginWithExternalProvider ? loginExternalProviderPath : checkoutPath)
  }

  return (
    <div className="mx-2 justify-center text-center">
      {!preferences?.store ? (
        <div className="mb-3 flex w-full justify-center">
          <WarningBox>{t(`${i18nProduct}.inner.actions.labelAddAddressOrSelectStore`)}</WarningBox>
        </div>
      ) : null}
      <Button
        className="h-12 text-base"
        primary
        full
        onClick={goToReview}
        loading={redirecting}
        disabled={!preferences?.store}
      >
        {t(`${i18nGeneric}.continue`)}
      </Button>
    </div>
  )
}
