import TextSkeleton from '@components/V3/Text/TextSkeleton'

export default function ItemSkeleton() {
  return (
    <div className="flex flex-col py-5 animate-pulse">
      <div className="flex items-start">
        <div className="rounded w-14 h-14 bg-slate-200 dark:bg-zinc-600 mr-3"></div>
        <div className="flex-1 flex flex-col space-y-2">
          <TextSkeleton type="body" className="!h-4 w-4/5" />
          <TextSkeleton type="body" className="!h-3 w-3/5" />
          <TextSkeleton type="body" className="!h-3 w-3/5" />
        </div>
        <div className="flex flex-col text-sm items-end">
          <TextSkeleton type="body" className="!h-4 w-16" />
        </div>
      </div>
    </div>
  )
}
