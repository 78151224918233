import React, {Suspense, useState} from 'react'
import {useOnEvent} from 'react-app-events'
import {FaRegClock} from 'react-icons/fa'
import {MdLocationPin, MdWarningAmber} from 'react-icons/md'
import DeliveryWizard from '@components/DeliveryWizard'
import useIsOutOfBounds from '@components/DeliveryWizard/internals/hooks/useIsOutOfBounds'
import TextSkeleton from '@components/V3/Text/TextSkeleton'
import classnames from '@helpers/misc/classnames'
import useMessage from '@hooks/useMessage'
import {useTranslation} from 'next-i18next'

import AddressLabel from './AddressLabel.main'
import TimeLabel from './TimeLabel.main'

function InnerDeliveryHeaderMobile() {
  const [open, setOpen] = useState(false)
  const isOutOfBounds = useIsOutOfBounds()
  const showMessage = useMessage()
  const {t} = useTranslation('website', {keyPrefix: 'delivery'})

  useOnEvent('hiddenPriceProductOpened', async () => {
    showMessage(t('hiddenPriceWarning'))
    setOpen(true)
  })

  return (
    <>
      <div
        className={classnames(
          'primaryBackgroundColor primaryBorderColor border-1 mt-4 flex w-full cursor-pointer items-center justify-between p-4 font-semibold text-white',
          {
            // Not sticky (for now)
            // 'sticky z-[1000] h-[51px] top-[49px] -mt-1': true
          }
        )}
        onClick={() => setOpen(true)}>
        <div className="flex flex-1 items-center gap-1 overflow-hidden">
          <div className="flex items-center">
            {isOutOfBounds ? (
              <MdWarningAmber className="mr-1 inline-block h-5 w-5 text-orange-200" />
            ) : (
              <MdLocationPin className="h-4 w-4" />
            )}
          </div>
          <AddressLabel />
        </div>
        {isOutOfBounds ? null : (
          <div className="flex flex-1 items-center justify-end gap-1 overflow-hidden">
            <div className="flex items-center">
              <FaRegClock className="h-4 w-4" />
            </div>
            <TimeLabel />
          </div>
        )}
      </div>
      <DeliveryWizard.AsModal
        actionLocation="order-delivery"
        isOpen={open}
        onClose={() => setOpen(false)}
      />
    </>
  )
}

function Skeleton() {
  return (
    <div className="mt-4 flex h-14 w-full animate-pulse flex-row justify-between bg-gray-100 p-4 dark:bg-zinc-800">
      <TextSkeleton type="subtitle" className="w-[45%]" />
      <TextSkeleton type="subtitle" className="w-[45%]" />
    </div>
  )
}

export default function DeliveryHeaderMobile() {
  return (
    <Suspense fallback={<Skeleton />}>
      <InnerDeliveryHeaderMobile />
    </Suspense>
  )
}
