import {useState} from 'react'
import {GetCheckoutV3Query} from '@data/__generated__/types.main'
import {deleteCartItemMutation} from '@data/mutations/cart/deleteCartItem.preferences'
import {EVENTS} from '@helpers/analytics/events'
import {PurchaseTaxonomies} from '@helpers/analytics/events/purchase'
import {sendEvent} from '@helpers/analytics/sendEvents'
import logger from '@helpers/logger'
import useAnalyticLocation from '@hooks/useAnalyticLocation'
import useEventsBaseProperties from '@hooks/useEventsBaseProperties'
import useMessage from '@hooks/useMessage'
import useWebsiteId from '@hooks/useWebsiteId'
import {clientNames} from '@providers/services'
import {useMutate, useRefetchQueries} from 'apollo-hooks'

interface Props {
  cartItemId?: string
  item?: GetCheckoutV3Query['preferences']['cart']['items'][number]
  menuId?: string
}

export default function useDeleteItem(props: Props) {
  const eventBaseProperties = useEventsBaseProperties()
  const websiteId = useWebsiteId()
  const mutate = useMutate()
  const [loading, setLoading] = useState<boolean>(false)
  const cartItemId = props?.cartItemId
  const item = props?.item
  const menuId = props?.menuId
  const showMessage = useMessage()
  const refetch = useRefetchQueries()
  const actionLocation = useAnalyticLocation('purchaseEdit')

  const submit = async () => {
    if (loading) return
    setLoading(true)
    try {
      await mutate({
        mutation: deleteCartItemMutation,
        variables: {cartItemId, websiteId},
        clientName: clientNames.preferences
      })
      await refetch(['getCartDetails', 'getPreferencesForResume', 'checkoutTotalsSummary'])
    } catch (error) {
      showMessage('Lo sentimos, no se pudo eliminar el producto del carrito')
      logger.error('Error deleteItem cartV3', error)
    }
    setLoading(false)

    sendEvent<PurchaseTaxonomies['productEdited']>(EVENTS.purchase.productEdited, {
      productEditAction: item?.amount === 0 ? 'delete' : 'edit',
      productId: item?.product?._id ?? '',
      productAmount: item?.amount,
      isAmountEdited: true,
      isOutOfStock: item?.isOutOfStock,
      menuId: menuId ?? '',
      actionLocation: actionLocation,
      ...eventBaseProperties
    })
  }

  return {
    submit,
    loading
  }
}
