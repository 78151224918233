import TextSkeleton from '@components/V3/Text/TextSkeleton'

export default function CartResumeSkeleton() {
  return (
    <div className="flex flex-col px-2 py-4 justify-between">
      <div className="flex justify-between">
        <TextSkeleton type="body" className="!h-4 w-2/5" />
        <TextSkeleton type="body" className="!h-4 w-1/5" />
      </div>
      <div className="flex justify-between">
        <TextSkeleton type="body" className="!h-4 w-2/5" />
        <TextSkeleton type="body" className="!h-4 w-1/5" />
      </div>
      <div className="flex justify-between">
        <TextSkeleton type="body" className="!h-4 w-2/5" />
        <TextSkeleton type="body" className="!h-4 w-1/5" />
      </div>
    </div>
  )
}
