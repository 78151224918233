import {getSavedValue} from '@hooks/useSessionStorageState'

const loginExternalProviderPath = '/auth/login-external-provider'

const isLoginWithExternalProvider = (loginWithExternalProvidersAvailable: string[]) => {
  const providerOpenWebview = getSavedValue('openByWebview')

  return providerOpenWebview && loginWithExternalProvidersAvailable?.includes(providerOpenWebview)
}

export default isLoginWithExternalProvider

export {loginExternalProviderPath}
