import {useTranslation} from 'next-i18next'

export default function NoItems() {
  const {t} = useTranslation('website', {keyPrefix: 'order.cart'})
  return (
    <div className="full-w flex-row justify-center text-center p-6">
      <h5 className="flex-1 break-normal text-base">{t('addProductsToCart')}</h5>
      <p className="flex-1 break-normal font-light text-sm">{t('productsDisplayExplanation')}</p>
    </div>
  )
}
