import SuspenseLoading from '@components/SuspenseLoading'
import Pill from '@components/V3/Pill'
import {useUpdateItemQuantity} from '@data/mutations/cart/updateItemQuantity.preferences'
import {useUserPreferences} from '@data/queries/preferences/userPreferences.main'
import {EVENTS} from '@helpers/analytics/events'
import {PurchaseTaxonomies} from '@helpers/analytics/events/purchase'
import {sendEvent} from '@helpers/analytics/sendEvents'
import useAnalyticLocation from '@hooks/useAnalyticLocation'
import useDebounce from '@hooks/useDebounce'
import useEventsBaseProperties from '@hooks/useEventsBaseProperties'
import {useTranslation} from 'next-i18next'
import React, {useCallback, useContext, useEffect, useState} from 'react'
import {MdAdd, MdDelete, MdRemove} from 'react-icons/md'

import ItemContext from '../ItemContext'
import QuantityButton from '../QuantityButton'

import EditProduct from './EditProduct'

export default function Actions({onDelete}: {onDelete: (any) => Promise<void>}) {
  const {t} = useTranslation('generic')
  const item = useContext(ItemContext)
  const [isEditing, setIsEditing] = useState(false)
  const [loadingEdit, setLoading] = useState(false)
  const {updateItemQuantity} = useUpdateItemQuantity({
    refetchQueries: ['getCartDetails', 'getPreferencesForResume', 'checkoutTotalsSummary'],
  })
  const updateQuantity = useCallback(
    quantity => {
      setLoading(true)
      updateItemQuantity({cartItemId: item._id, quantity})
      setLoading(false)
    },
    [item?._id],
  )
  const actionLocation = useAnalyticLocation('editProduct')
  const [quantity, setQuantity] = useState(item.amount)
  const [debouncedQuantity, isDebouncing] = useDebounce(quantity, 1000)
  const eventBaseProperties = useEventsBaseProperties()
  const preferences = useUserPreferences()

  const loading = loadingEdit || isDebouncing

  useEffect(() => {
    // This triggers the server-side update only after the debouncer has finished
    if (debouncedQuantity !== item.amount) {
      updateQuantity(debouncedQuantity)
      sendEvent<PurchaseTaxonomies['productEdited']>(EVENTS.purchase.productEdited, {
        productEditAction: item?.amount === 0 ? 'delete' : 'edit',
        productId: item?.product?._id ?? '',
        productAmount: item?.amount,
        isAmountEdited: true,
        isOutOfStock: item?.isOutOfStock,
        menuId: preferences?.menuId ?? '',
        actionLocation: actionLocation,
        ...eventBaseProperties,
      })
    }
  }, [debouncedQuantity])

  useEffect(() => {
    // If the item amount gets updated elsewhere, we need to update our local indicator
    if (item.amount !== quantity) {
      setQuantity(item.amount)
    }
  }, [item.amount])

  return (
    <>
      <div className="flex flex-1 justify-start items-center">
        {quantity === 1 ? (
          <QuantityButton onClick={onDelete}>
            <div className="flex justify-center">
              <MdDelete />
            </div>
          </QuantityButton>
        ) : (
          <QuantityButton onClick={() => setQuantity(quantity - 1)}>
            <div className="flex justify-center">
              <MdRemove />
            </div>
          </QuantityButton>
        )}
        <div className="text-sm font-semibold px-2">{quantity}</div>
        <QuantityButton onClick={() => setQuantity(quantity + 1)}>
          <div className="flex justify-center">
            <MdAdd />
          </div>
        </QuantityButton>
      </div>
      <Pill
        small
        loading={loading}
        showCaret={false}
        onClick={() => {
          setIsEditing(true)
        }}
      >
        {t('fields.edit')}
      </Pill>
      {isEditing && (
        <SuspenseLoading>
          <EditProduct
            setLoading={setLoading}
            cartItemId={item._id}
            isOpen={isEditing}
            onClose={() => setIsEditing(false)}
          />
        </SuspenseLoading>
      )}
    </>
  )
}
