import isNull from 'lodash/isNull'
import {useRouter} from 'next/router'

import getQueryParam from './getQueryParam'
import setQueryParam from './setQueryParam'

export default function useURLState(key, initialValue = null, replaceHistory = true) {
  const router = useRouter()

  const queryParam = getQueryParam({query: router.query, key}) || initialValue
  const onChange = newValue => {
    if (isNull(newValue)) {
      setQueryParam({key, value: null, replaceHistory, router})
    } else {
      setQueryParam({key, value: newValue, replaceHistory, router})
    }
  }

  return [queryParam, onChange]
}
