import {GetCartDetailsQuery, GetCartDetailsQueryVariables} from '@data/__generated__/types.main'
import useWebsiteId from '@hooks/useWebsiteId'
import {useApolloQuery} from 'apollo-hooks'
import gql from 'graphql-tag'

export type ItemCartDetails = GetCartDetailsQuery['preferences']['cart']['items'][0]

export default function useCartDetails() {
  const websiteId = useWebsiteId()

  const {preferences} = useApolloQuery<GetCartDetailsQuery, GetCartDetailsQueryVariables>({
    query: gql`
      query getCartDetails($websiteId: ID!) {
        preferences: userPreferences(websiteId: $websiteId) {
          _id
          cart {
            items {
              _id
              description
              descriptionItems {
                title
                selections
              }

              modifiers {
                modifierId
                optionsIds
              }
              unitPrice
              realPrice: unitPrice(includeDiscount: false)
              amount
              outOfStockMessage
              isOutOfStock

              product {
                _id
                name
                image {
                  _id
                  url: resizedURL(width: 60, height: 60, format: "webp")
                }
              }
            }
          }
        }
      }
    `,
    variables: {websiteId},
    fetchPolicy: 'cache-first'
  })

  return preferences.cart
}
