import React from 'react'
import classnames from '@helpers/misc/classnames'
import useIsDarkMode from '@hooks/useIsDarkMode'

export default function QuantityButton({
  children,
  onClick
}: {
  children: React.ReactNode
  onClick: (any) => void
}) {
  const isDarkMode = useIsDarkMode()
  return (
    <button
      onClick={onClick}
      className={classnames({
        'text-sm font-semibold w-8 h-8 rounded-full items-center justify-center': true,
        border: true,
        'border-gray-200': !isDarkMode,
        'border-zinc-600': isDarkMode,
        'bg-gray-100': !isDarkMode,
        'bg-zinc-600': isDarkMode,
        'transition ease-in-out duration-150': true,
        'hover:bg-gray-200': !isDarkMode,
        'hover:bg-zinc-400': isDarkMode
      })}>
      {children}
    </button>
  )
}
