import {useUserPreferences} from '@data/queries/preferences/userPreferences.main'
import {EVENTS} from '@helpers/analytics/events'
import {PurchaseTaxonomies} from '@helpers/analytics/events/purchase'
import {sendEvent} from '@helpers/analytics/sendEvents'
import classnames from '@helpers/misc/classnames'
import useEventsBaseProperties from '@hooks/useEventsBaseProperties'
import useIsAnalyticsV2Avail from '@page-components/AnalyticsV2/hooks/useIsAnalyticsV2Available'
import TrackCartView from '@page-components/AnalyticsV2/tracking-components/TrackCartView'
import useCartDetails from '@page-components/Order/Cart/Content/hooks/useCartDetails.main'
import {Suspense, useEffect, useState} from 'react'

import DeleteItem from './DeleteItem'
import Item from './Item'
import NoItems from './NoItems'
import CartItemsSkeleton from './Skeleton'

function InnerCartItems() {
  const cart = useCartDetails()
  const [open, setOpen] = useState(false)
  const [selectedItem, setSelectedItem] = useState<string | null>(null)

  const userPrefs = useUserPreferences()
  const data = {preferences: userPrefs}
  const baseEventProperties = useEventsBaseProperties()
  const isAnalyticsV2Avail = useIsAnalyticsV2Avail()

  useEffect(() => {
    if (isAnalyticsV2Avail) return
    const {productAmount, subTotalPrice} = (cart?.items || []).reduce(
      (acc, item) => {
        acc.productAmount += item.amount
        acc.subTotalPrice += item.unitPrice * item.amount
        return acc
      },
      {
        productAmount: 0,
        subTotalPrice: 0,
      },
    )
    sendEvent<PurchaseTaxonomies['cartPageViewed']>(EVENTS.purchase.cartPageViewed, {
      ...baseEventProperties,
      cartItemsAmount: cart?.items?.length ?? 0,
      productAmount,
      subTotalPrice,
      isScheduledOrder: data?.preferences?.time !== 'now',
      menuId: data?.preferences?.menuId ?? '',
    })
  }, [])

  const handleDelete = async (cartItemId: string) => {
    await setSelectedItem(cartItemId)
    setOpen(true)
  }

  if (!cart) return null
  if (!cart?.items?.length) return <NoItems />

  return (
    <>
      <div
        className={classnames(
          'flex flex-col divide-y divide-solid divide-gray-200 overflow-auto px-3',
          'dark:divide-zinc-600',
        )}
      >
        {cart.items.map(item => (
          <div key={item._id}>
            <Item item={item} onDelete={() => handleDelete(item._id)} />
          </div>
        ))}
      </div>
      {open ? (
        <DeleteItem cartItemId={selectedItem} onCancel={() => setOpen(false)} open={open} />
      ) : null}
      {isAnalyticsV2Avail && <TrackCartView userPrefs={userPrefs} cart={cart} />}
    </>
  )
}

export default function CartItems() {
  return (
    <Suspense fallback={<CartItemsSkeleton />}>
      <InnerCartItems />
    </Suspense>
  )
}
