import Button from '@components/V3/Button'
import {useTranslation} from 'next-i18next'

export default function OutOfStockButton() {
  const {t} = useTranslation()
  const i18nWebsite = 'website:order.cart.content.resume'

  return (
    <div className="text-center m-4 mb-0">
      <Button primary disabled>
        {t(`${i18nWebsite}.labelProductsOutStock`)}
      </Button>
    </div>
  )
}
