import ClientSuspense from '@components/ClientSuspense'
import {useEffect} from 'react'
import useLazyAnalyticsV2 from '../hooks/useLazyAnalyticsV2'
import {INTERNAL_EVENTS} from '../types/analyticsServicesTypes'

export interface TrackCartViewProps {
  cart: any
  userPrefs: any
}

function TrackCartViewInner(props: TrackCartViewProps) {
  const {cart, userPrefs} = props
  const analyticsV2 = useLazyAnalyticsV2()
  useEffect(() => {
    if (!userPrefs) return
    const {productAmount, subTotalPrice} = (cart?.items || []).reduce(
      (acc, item) => {
        acc.productAmount += item.amount
        acc.subTotalPrice += item.unitPrice * item.amount
        return acc
      },
      {
        productAmount: 0,
        subTotalPrice: 0,
      },
    )
    analyticsV2.trackEventAsDefaultOnInternal(INTERNAL_EVENTS.cartPageViewed, {
      cartItemsAmount: cart?.items?.length ?? 0,
      productAmount,
      subTotalPrice,
      isScheduledOrder: userPrefs?.time !== 'now',
      menuId: userPrefs?.menuId ?? '',
    })
  }, [])
  return null
}

export default function TrackCartView(props: Readonly<TrackCartViewProps>) {
  return (
    <ClientSuspense fallback={null}>
      <TrackCartViewInner {...props} />
    </ClientSuspense>
  )
}
