import {Suspense} from 'react'
import formatNumber from '@i18n/formatNumber'
import usePreferencesForResume from '@page-components/Order/Cart/Content/Resume/hooks/usePreferencesForResume.main'
import {useTranslation} from 'next-i18next'

import CartResumeSkeleton from './Skeleton'

function InnerCartResume() {
  const {t} = useTranslation('website', {keyPrefix: 'order.cart.content.resume'})
  const preferences = usePreferencesForResume()
  const cart = preferences?.cart
  if (!cart?.items?.length) return null
  const totalDiscounts = cart.realPrice - cart.subtotal

  return (
    <div className="flex flex-col px-2 py-4 justify-between">
      {totalDiscounts === 0 ? null : (
        <div className="flex text-sm">
          <div className="flex-1">{t('labelProductsTotal')}</div>
          <div>{formatNumber(cart.realPrice, 'money')}</div>
        </div>
      )}
      {totalDiscounts === 0 ? null : (
        <div className="flex text-sm">
          <div className="flex-1">{t('labelDiscountsTotal')}</div>
          <div>- {formatNumber(totalDiscounts, 'money')}</div>
        </div>
      )}
      <div className="flex font-semibold text-sm">
        <div className="flex-1">{t('labelSubtotal')}</div>
        <div>{formatNumber(cart.subtotal, 'money')}</div>
      </div>
    </div>
  )
}

export default function CartResume() {
  return (
    <Suspense fallback={<CartResumeSkeleton />}>
      <InnerCartResume />
    </Suspense>
  )
}
