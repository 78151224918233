import {useState} from 'react'
import {
  UpdateCartItemV2Mutation,
  UpdateCartItemV2MutationVariables
} from '@data/__generated__/types.preferences'
import useWebsiteId from '@hooks/useWebsiteId'
import {clientNames} from '@providers/services'
import {useMutate, useRefetchQueries} from 'apollo-hooks'
import gql from 'graphql-tag'

export const updateItemQuantityMutation = gql`
  mutation updateCartItemV2($websiteId: ID!, $cartItemId: ID!, $quantity: Float) {
    updateItemQuantity(websiteId: $websiteId, cartItemId: $cartItemId, quantity: $quantity) {
      _id
    }
  }
`

export function useUpdateItemQuantity(
  opts: {
    refetchQueries?: string[]
  } = {}
) {
  const websiteId = useWebsiteId()
  const mutate = useMutate()
  const [loading, setLoading] = useState<boolean>(false)
  const refetch = useRefetchQueries()

  const updateItemQuantity = async ({
    quantity,
    cartItemId
  }: {
    quantity: number
    cartItemId: string
  }) => {
    if (loading) return
    setLoading(true)

    await mutate<UpdateCartItemV2Mutation, UpdateCartItemV2MutationVariables>({
      mutation: updateItemQuantityMutation,
      variables: {websiteId, cartItemId, quantity},
      clientName: clientNames.preferences
    })

    await refetch(
      opts?.refetchQueries ?? ['getCartDetails', 'getPreferencesForResume', 'checkoutTotalsSummary']
    )
    setLoading(false)
  }

  return {updateItemQuantity, loading}
}
