import Button from '@components/V3/Button'
import formatNumber from '@i18n/formatNumber'
import {useTranslation} from 'next-i18next'

export default function AmountToReachButton(props) {
  const {t} = useTranslation()
  const i18nWebsite = 'website:order.cart.content.resume'
  const {amountToReach} = props

  return (
    <div className="text-center m-2 mb-0">
      <Button primary disabled>
        {t(`${i18nWebsite}.labelNotReachedMin`)}
      </Button>
      <div className="description" style={{marginTop: 5}}>
        {t(`${i18nWebsite}.labelAddMoreProducts`, {
          money: formatNumber(amountToReach, 'money')
        })}
      </div>
    </div>
  )
}
