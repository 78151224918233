import {Suspense} from 'react'

import CartButton from './CartButton'
import CartDelivery from './CartDelivery'
import CartItems from './CartItems'
import CartResume from './CartResume'
import CartContentSkeleton from './Skeleton'

function InnerCartContent() {
  return (
    <>
      <div className="flex-initial flex-col h-20 align-center py-4 px-2 border-b dark:border-zinc-600">
        <CartDelivery />
      </div>
      <div className="flex-1 overflow-y-auto">
        <CartItems />
      </div>
      <div className="flex-initial flex-col align-center border-t dark:border-zinc-600">
        <CartResume />
        <CartButton />
      </div>
    </>
  )
}

export default function CartContent() {
  return (
    <Suspense fallback={<CartContentSkeleton />}>
      <InnerCartContent />
    </Suspense>
  )
}
