import React from 'react'
import classnames from '@helpers/misc/classnames'
import useIsDarkMode from '@hooks/useIsDarkMode'

import {TextType} from '.'

export interface Props {
  type: TextType
  className?: string
}

export default function TextSkeleton({className = '', type}: Props) {
  const isDarkMode = useIsDarkMode()

  return (
    <div
      className={classnames(
        {
          'animate-pulse rounded mb-1': true,
          'bg-slate-200': !isDarkMode,
          'bg-zinc-600': isDarkMode,
          'h-3': type === 'caption',
          'h-6': type === 'title',
          'h-5': type === 'subtitle' || type === 'body',
          'w-full': !className?.includes('w-')
        },
        className
      )}></div>
  )
}
