import React, {useContext} from 'react'
import classnames from '@helpers/misc/classnames'
import useIsDarkMode from '@hooks/useIsDarkMode'

import ItemContext from './ItemContext'

export default function Description() {
  const item = useContext(ItemContext)
  const darkMode = useIsDarkMode()
  return (
    <>
      <div className="flex space-x-2">
        {item?.isOutOfStock ? '(Agotado)' : null}
        <span
          className={classnames({
            'text-sm font-semibold': true,
            'text-gray-400 line-through': item?.isOutOfStock,
            'text-gray-800': !darkMode,
            'text-white': darkMode
          })}>
          {item?.product.name}
        </span>
      </div>

      {item?.descriptionItems?.length
        ? item.descriptionItems.map((desc, di) => (
            <div
              key={`${item._id}_descriptionItem_${di}`}
              className="font-light text-sm lightTextColor dark:text-gray-200">
              <span>{desc.title}</span>
              <ul className="list-disc list-inside">
                {desc.selections.map((selection, si) => (
                  <li key={`${item._id}_selection_${si}`}>{selection}</li>
                ))}
              </ul>
            </div>
          ))
        : null}
    </>
  )
}
