import React from 'react'
import {MdWarning} from 'react-icons/md'
import Button from '@components/V3/Button'
import Modal from '@components/V3/Modal'
import {useUserPreferences} from '@data/queries/preferences/userPreferences.main'
import useCartDetails from '@page-components/Order/Cart/Content/hooks/useCartDetails.main'

import Item from '../Item'

import useDeleteItem from './useDeleteItem'

export default function DeleteItem({
  cartItemId,
  onCancel,
  open
}: {
  cartItemId: string
  onCancel: () => void
  open: boolean
}) {
  const cart = useCartDetails()
  const preferences = useUserPreferences()
  const item = cart?.items?.find(item => item._id === cartItemId)

  const {submit, loading} = useDeleteItem({cartItemId, item, menuId: preferences.menuId})

  if (!item) return null

  const onDelete = async () => {
    submit()
    onCancel()
  }

  return (
    <Modal
      forceJustoFont
      hasHeightLimit
      isOpen={open}
      onClose={onCancel}
      headerDivider
      header={
        <div className="flex flex-col justify-center items-center">
          <MdWarning className="text-red-500" size={32} />
          <h1 className="text-lg font-semibold py-2">¿Estás seguro que quieres eliminarlo?</h1>
        </div>
      }>
      <div className="flex flex-col divide-y divide-gray-100 text-sm">
        <Item showActions={false} showPrice={false} item={item} />
        <div className="flex space-x-4 py-4">
          <Button disabled={loading} loading={loading} full big danger onClick={onDelete}>
            {loading ? '' : 'Sí, quiero eliminarlo'}
          </Button>
          <Button disabled={loading} full big onClick={onCancel}>
            No, quiero mantenerlo
          </Button>
        </div>
      </div>
    </Modal>
  )
}
