import {
  GetPreferencesForResumeQuery,
  GetPreferencesForResumeQueryVariables,
} from '@data/__generated__/types.main'
import useWebsiteId from '@hooks/useWebsiteId'
import {useApolloQuery} from 'apollo-hooks'
import gql from 'graphql-tag'

export default function usePreferencesForResume() {
  const websiteId = useWebsiteId()
  const {preferences} = useApolloQuery<
    GetPreferencesForResumeQuery,
    GetPreferencesForResumeQueryVariables
  >({
    query: gql`
      query getPreferencesForResume($websiteId: ID!) {
        preferences: userPreferences(websiteId: $websiteId) {
          _id
          sessionEmail
          sessionEmailHasName
          deliveryType
          menuId
          store {
            _id
            currentExtraDeliveryCost
          }

          address {
            _id
          }

          bagsRequired

          cart {
            deliveryFee
            serviceFee
            itemsPrice
            realPrice: itemsPrice(includeDiscount: false)
            subtotal: itemsPrice(
              includeDiscount: true
              includeCoupon: true
              includeJustoCoins: false
              includeLoyaltyDiscount: false
              includeWebsiteCoins: false
            )
            couponStatus {
              discount
            }
            totalPrice
            bagAmount
            calculatedBagsRequired
            maxBagsAllowed
            items {
              _id
              isOutOfStock
              productId
              product {
                _id
                name
              }
              amount
            }
            deliveryType
            storeId
          }

          userId
        }
      }
    `,
    variables: {websiteId},
    fetchPolicy: 'cache-and-network',
  })

  return preferences
}
