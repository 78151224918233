import {Suspense, useState} from 'react'
import useIsSpecialLayout from '@hooks/useIsSpecialLayout'
import useInitialData from '@page-components/Layout/useInitialData'
import usePreferencesForResume from '@page-components/Order/Cart/Content/Resume/hooks/usePreferencesForResume.main'

import AmountToReachButton from './AmountToReachButton'
import CartShowTerms from './CartShowTerms'
import ChatButton from './ChatButton'
import ContinueButton from './ContinueButton'
import GoToMenuButton from './GoToMenuButton'
import OutOfStockButton from './OutOfStockButton'
import CartButtonSkeleton from './Skeleton'

function InnerCartButton() {
  const {website} = useInitialData()
  const preferences = usePreferencesForResume()
  const isSpecialLayout = useIsSpecialLayout()
  const [redirecting, setRedirecting] = useState(false)
  const itemsPrice = preferences?.cart?.itemsPrice
  const items = preferences?.cart?.items

  const amountToReachMinimum = () => {
    if (!website?.minimumOrderPrice || !itemsPrice) return 0
    const amount = website.minimumOrderPrice - itemsPrice
    if (amount > 0) return amount
    return 0
  }

  const hasAnyOutOfStock = items?.find(item => item?.isOutOfStock)
  if (hasAnyOutOfStock) return <OutOfStockButton />

  const amountToReach = amountToReachMinimum()
  if (amountToReach) {
    return <AmountToReachButton amountToReach={amountToReach} />
  }

  if (isSpecialLayout === 'chat')
    return <ChatButton redirecting={redirecting} setRedirecting={setRedirecting} />

  if (!items?.length) return <GoToMenuButton />

  return (
    <>
      <CartShowTerms website={website} userId={preferences?.userId} />
      <ContinueButton
        redirecting={redirecting}
        setRedirecting={setRedirecting}
        preferences={preferences}
        website={website}
      />
    </>
  )
}

export default function CartButton() {
  return (
    <Suspense fallback={<CartButtonSkeleton />}>
      <InnerCartButton />
    </Suspense>
  )
}
