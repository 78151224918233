import CartButtonSkeleton from './CartButton/Skeleton'
import CartDeliverySkeleton from './CartDelivery/Skeleton'
import CartItemsSkeleton from './CartItems/Skeleton'
import CartResumeSkeleton from './CartResume/Skeleton'

export default function CartContentSkeleton() {
  return (
    <>
      <div className="flex-initial flex-col align-center py-4 border-b">
        <CartDeliverySkeleton />
      </div>
      <div className="flex-1 overflow-y-auto">
        <CartItemsSkeleton />
      </div>
      <div className="flex-initial flex-col align-center border-t">
        <CartResumeSkeleton />
        <CartButtonSkeleton />
      </div>
    </>
  )
}
