import React from 'react'
import Image from '@components/Image'
import {GetCartDetailsQuery} from '@data/__generated__/types.main'
import classnames from '@helpers/misc/classnames'
import useIsDarkMode from '@hooks/useIsDarkMode'
import formatNumber from '@i18n/formatNumber'

import Actions from './Actions'
import Description from './Description'
import ItemContext from './ItemContext'

export interface ItemProps {
  item: GetCartDetailsQuery['preferences']['cart']['items'][number]
  onDelete?: (any) => Promise<void>
  showActions?: boolean
  showPrice?: boolean
}

export default function Item({item, onDelete, showActions = true, showPrice = true}: ItemProps) {
  const isDarkMode = useIsDarkMode()
  const hasDiscount = item.realPrice && item.realPrice !== item.unitPrice

  return (
    <ItemContext.Provider value={item}>
      <div className="flex flex-col py-5">
        <div className="flex items-start">
          <div className="rounded w-[56px] h-[56px] relative overflow-clip">
            {/* Product image */}
            {item.product?.image?.url && (
              <Image
                alt={item.product.name}
                layout="fill"
                objectFit="cover"
                src={item.product.image.url}
              />
            )}
          </div>
          <div
            className={classnames({
              'flex-1 flex flex-col px-3': true,
              'space-y-2': showPrice && hasDiscount
            })}>
            {/* Product title and description */}
            <Description />
          </div>
          {showPrice ? (
            <div className="flex flex-col text-sm items-end">
              {/* Product price */}
              {formatNumber(item.unitPrice * item.amount, 'money')}
              {hasDiscount ? (
                <div className="text-xs font-light line-through">
                  {formatNumber(item.realPrice * item.amount, 'money')}
                </div>
              ) : null}
            </div>
          ) : null}
        </div>
        {showActions ? (
          <div className="flex pl-16 ml-1 mt-2">
            {/* Quantity and Actions */}
            <Actions onDelete={onDelete} />
          </div>
        ) : null}
      </div>
    </ItemContext.Provider>
  )
}
