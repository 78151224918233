import React, {useState} from 'react'
import {useOnEvent} from 'react-app-events'
import ClientSuspense from '@components/ClientSuspense'
import DeliveryWizard from '@components/DeliveryWizard'
import Pill from '@components/V3/Pill'
import useMessage from '@hooks/useMessage'
import {useTranslation} from 'next-i18next'

import AddressAndTimeLabel from './AddressAndTimeLabel'
import CartDeliverySkeleton from './Skeleton'

function InnerCartDelivery() {
  const {t} = useTranslation('website', {keyPrefix: 'delivery'})
  const showMessage = useMessage()
  const [open, setOpen] = useState<boolean>(false)

  useOnEvent('hiddenPriceProductOpened', async () => {
    showMessage(t('hiddenPriceWarning'))
    setOpen(true)
  })

  return (
    <>
      <Pill
        showCaret={false}
        className="h-full w-full overflow-hidden"
        onClick={() => setOpen(true)}>
        <AddressAndTimeLabel />
      </Pill>
      <DeliveryWizard.AsModal
        actionLocation="order-delivery"
        isOpen={open}
        onClose={() => setOpen(false)}
      />
    </>
  )
}

export default function CartDelivery() {
  return (
    <ClientSuspense fallback={<CartDeliverySkeleton />}>
      <InnerCartDelivery />
    </ClientSuspense>
  )
}
