import useIsSpecialLayout from '@hooks/useIsSpecialLayout'
import useWebsiteId from '@hooks/useWebsiteId'
import {
  getWhatsAppCommerceNumber,
  getWhatsAppCommerceNumberVariables
} from '@page-components/Order/Cart/Content/__generated__/getWhatsAppCommerceNumber'
import {useApolloQuery} from 'apollo-hooks'
import gql from 'graphql-tag'

export default function useWhatsAppCommerceNumber() {
  const websiteId = useWebsiteId()
  const isSpecialLayout = useIsSpecialLayout()
  const {website} = useApolloQuery<getWhatsAppCommerceNumber, getWhatsAppCommerceNumberVariables>({
    query: gql`
      query getWhatsAppCommerceNumber($websiteId: ID) {
        website(websiteId: $websiteId) {
          _id
          whatsappChatCommerceNumber
        }
      }
    `,
    variables: {websiteId},
    omit: isSpecialLayout !== 'chat'
  })

  return website ? website.whatsappChatCommerceNumber : null
}
