import Button from '@components/V3/Button'
import formatNumber from '@i18n/formatNumber'
import useCartPreferences from '@page-components/Order/Cart/hooks/useCart.main'
import React, {useMemo} from 'react'
import {MdShoppingCart} from 'react-icons/md'

export interface CartMenuButtonProps {
  onClick: any
}

const CartButton = ({cart}: {cart: any}) => {
  const totalItems = useMemo(
    () => (cart?.items || []).reduce((total, item) => total + item.amount, 0),
    [cart?.items],
  )
  return (
    <div className="flex flex-row justify-between w-full text-sm">
      <div className="flex flex-row items-center">
        <MdShoppingCart size={24} />
        <div className="pl-2">Carrito ({totalItems})</div>
      </div>
      <div className="flex flex-row items-center">
        <div className="pl-2">{formatNumber(cart?.subtotal || 0, 'money')}</div>
      </div>
    </div>
  )
}

export default function CartMenuButton(props: Readonly<CartMenuButtonProps>) {
  const {onClick} = props
  const preferences = useCartPreferences()
  const {cart} = preferences
  if (!cart?.items?.length) return null

  return (
    <div className="px-4 z-50 fixed w-full bottom-5">
      <Button
        primary
        full
        big
        onClick={onClick}
        className="flex flex-row button-v3-primary h-14 font-sans rounded-md shadow-[0_0_16px_0_rgba(0,0,0,0.25)]"
      >
        <CartButton cart={cart} />
      </Button>
    </div>
  )
}
