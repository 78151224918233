import React from 'react'
import Button from '@components/V3/Button'

export default function CartButtonSkeleton() {
  return (
    <div className="text-center mx-2 justify-center animate-pulse">
      <Button className="h-12 text-base" primary full disabled>
        Cargando...
      </Button>
    </div>
  )
}
