import {useEffect, useState} from 'react'
import {useOnEvent} from 'react-app-events'
import ClientSuspense from '@components/ClientSuspense'
import useURLState from '@hooks/useURLState'
import useNavbarV2 from '@page-components/Layout/Website/Layouts/Default/NavbarV2/hooks/useNavbarV2'
import CartMenuButton from '@page-components/Order/CartV3/CartMobile/CartMenuButton'
import DeliveryHeaderMobile from '@page-components/Order/DeliveryHeaderMobile'

import CartModalContext from '../hooks/useCartModalContext'

import CartModal from './CartModal'

function InnerCartMobile() {
  const [seeTableOrder, setSeeTableOrder] = useURLState('seeTableOrder')
  const isOpenQueryParam = seeTableOrder === 'true'
  const [open, setOpen] = useState(isOpenQueryParam)
  const isNavbarV2Active = useNavbarV2()

  useEffect(() => {
    if (!open && isOpenQueryParam) setSeeTableOrder('false')
  }, [open, isOpenQueryParam])

  useEffect(() => {
    if (isOpenQueryParam) setOpen(true)
  }, [isOpenQueryParam, seeTableOrder])

  useOnEvent('setOpenCartMobile', value => {
    setOpen(value)
  })

  return (
    <>
      {!isNavbarV2Active ? (
        <DeliveryHeaderMobile />
      ) : null}
      <CartModalContext.Provider value={{closeModal: () => setOpen(false)}}>
        <CartMenuButton onClick={() => setOpen(!open)} />
        {open ? <CartModal isOpen={open} setOpen={setOpen} /> : null}
      </CartModalContext.Provider>
    </>
  )
}
export default function CartMobile() {
  return (
    <ClientSuspense fallback={null}>
      <InnerCartMobile />
    </ClientSuspense>
  )
}
