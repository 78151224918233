import Modal from '@components/V3/Modal'
import CartContent from '@page-components/Order/CartV3/CartContent'

interface Props {
  isOpen?: boolean
  setOpen?: (isOpen: boolean) => void
}

export default function CartModal(props: Props) {
  const {isOpen, setOpen} = props

  if (!isOpen) return null

  return (
    <Modal
      onClose={() => {
        setOpen(false)
      }}
      isOpen={isOpen}
      compact
      className="rounded-t-lg dark:bg-zinc-800"
      header={<span className="px-3 text-xl font-semibold">Tu Carrito</span>}>
      <div className="flex flex-col h-[85dvh] px-2">
        <CartContent />
      </div>
    </Modal>
  )
}
