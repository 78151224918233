import {useContext} from 'react'
import Button from '@components/V3/Button'
import {useTranslation} from 'next-i18next'

import CartModalContext from '../../hooks/useCartModalContext'

export default function GoToMenuButton() {
  const {t} = useTranslation('website', {keyPrefix: 'order.cart.content.resume'})
  const cartModalContext = useContext(CartModalContext)

  if (!cartModalContext.closeModal) return null

  return (
    <div className="text-center m-2 mb-0">
      <div className="text-center mx-5">
        <Button className="h-12 text-base" onClick={cartModalContext.closeModal} primary full>
          {t('labelGoToMenu')}
        </Button>
      </div>
    </div>
  )
}
