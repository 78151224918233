import {getSavedValue} from '@hooks/useSessionStorageState'
import Link from 'next/link'

export default function CartShowTerms(props) {
  const {website, userId} = props

  const yapeDocuments = {
    termsAndConditionsURL: 'https://yape.tambo.pe/documentos-legales/termsAndConditions',
    privacyURL: 'https://yape.tambo.pe/documentos-legales/privacy',
  }

  const terms = {
    yapeApp: yapeDocuments,
    yapeAppStaging: yapeDocuments,
  }

  const providerOpenWebview = getSavedValue('openByWebview')
  const isLoginWithExternalProvider =
    providerOpenWebview &&
    website?.loginWithExternalProvidersAvailable?.includes(providerOpenWebview)

  if (userId || !isLoginWithExternalProvider) return <div />

  return (
    <div className="flex flex-col px-2 py-4 justify-between">
      <div className="flex text-sm">
        <div className="flex-1">
          Al dar Continuar, aceptarás los{' '}
          <Link href={terms[providerOpenWebview]?.termsAndConditionsURL || '#'}>
            términos y condiciones
          </Link>
          ,{' '}
          <Link href={terms[providerOpenWebview]?.privacyURL || '#'}>políticas de privacidad</Link>{' '}
          y compartir tus datos personales con {website.name}. Esta autorización solo se realizará
          en tu primera compra
        </div>
      </div>
    </div>
  )
}
