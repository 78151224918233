import getQueryObject from '@helpers/misc/getQueryObject'
import isNull from 'lodash/isNull'
import {NextRouter} from 'next/router'

export default function setQueryParam({
  key,
  value,
  router,
  replaceHistory
}: {
  key: string
  value: any
  router: NextRouter
  replaceHistory?: boolean
}) {
  const searchParams = getQueryObject(router)
  if (isNull(value)) {
    delete searchParams[key]
  } else {
    searchParams[key] = value
  }

  if (replaceHistory) {
    router.push({pathname: window?.location?.pathname, query: searchParams})
  }
}
