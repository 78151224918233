import Button from '@components/V3/Button'
import useWhatsAppCommerceNumber from '@page-components/Order/Cart/Content/useWhatsAppCommerceNumber'
import {useRouter} from 'next/router'
import {useTranslation} from 'next-i18next'

export default function ChatButton(props) {
  const {t} = useTranslation()
  const router = useRouter()
  const i18nWebsite = 'website:order.cart.content.resume'
  const {redirecting, setRedirecting} = props
  const whatsappChatCommerceNumber = useWhatsAppCommerceNumber()

  const goToWhatsapp = () => {
    setRedirecting(true)

    if (!whatsappChatCommerceNumber) {
      const justoBotPhoneNumber = '18453032928' // TODO: CRUD and fetch from backend
      router.push(`/whatsapp-redirect?phone=${justoBotPhoneNumber}&text=Completar`)
      return
    }

    router.push(
      `/whatsapp-redirect?phone=${whatsappChatCommerceNumber.replace('+', '')}&text=Completar`
    )
  }

  return (
    <div className="text-center m-2 mb-0">
      <Button primary onClick={goToWhatsapp} loading={redirecting}>
        {t(`${i18nWebsite}.labelCompleteOrderInWhatsapp`)}
      </Button>
    </div>
  )
}
